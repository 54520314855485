.editorLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(241, 243, 244);

  .topNav {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.notificationContainer {
  left: 50%;
  transform: translateX(-50%);
}
