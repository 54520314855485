.topNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .spindlLogo {
    display: block;
    width: 3.796875rem;
    height: 1.5rem;
  }
  
  .accountMenuDropDown {
    display: flex;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    align-items: flex-start;

    .content {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
    
    .spindlLogo {
      width: 5.0625rem;
      height: 2rem;
    }
    
  }
}
