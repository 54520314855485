.teamCardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;

  .teamCard {
    display: flex;
    width: calc(50% - 0.5rem);
    height: 10.625rem;
  }
  
  @media (min-width: 1440px) {
    gap: 1.5rem;

    .teamCard {
      width: calc(25% - 1.125rem);
      height: 13.75rem;
    }
    
  }
}
