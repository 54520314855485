.subNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  width: 100%;

  .horizontalTabList {
    display: flex;
    width: 13.6875rem;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {

    .horizontalTabList {
      width: 15rem;
    }
    
  }
}
