.titleWithDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    width: unset;
    align-self: center;
    margin-top: 0.125rem;
  }
  
  .subtitle {
    display: flex;
    width: unset;
    align-self: center;
  }
  
  @media (min-width: 583px) {

    .title {
      margin-top: 0;
    }
    
    .subtitle {
      margin-top: 0.25rem;
    }
    
  }
}
