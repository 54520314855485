.leftNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  height: 48.6875rem;

  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.9375rem;
    height: unset;
    align-self: stretch;
  }
  
  .appDropDown {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .serviceMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0rem 0.75rem 0.75rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .serviceList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .settingsMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .subheadingDivider {
    display: none;
  }
  
  .subHeadingDivider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .settingsList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .border1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    height: 64.6875rem;

    .menuContent {
      width: 17.625rem;
      flex: 1 1 auto;
    }
    
    .serviceMenu {
      padding: 1rem 0rem 1rem 1rem;
    }
    
    .settingsMenu {
      padding: 0rem 0rem 1rem 1rem;
    }
    
    .subheadingDivider {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
    }
    
    .subHeadingDivider {
      display: none;
      width: unset;
    }
    
    .settingsList {
      margin-top: 1rem;
    }
    
  }
}
