.teamCardAddNewButton {
  cursor: pointer;
}

.textOverflowEllipsis {
  div p,
  div h3 {
    width: 13rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
