.teammateInviteModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  width: 25rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .closeButton {
    display: flex;
  }
  
  .border {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 2rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .emailField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .addButton {
    display: flex;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    width: 30rem;

    .content {
      background-color: rgb(255, 255, 255);
    }
    
  }
}
