.appDropDown {
  display: flex;
  width: unset;
  align-self: stretch;

  button {
    width: 100%;
  }

  div[role=menu] {
    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 110%;
  }
}