.signUpBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  width: 28.25rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .logoWithHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .nameField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .emailField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .passwordField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .confirmPasswordField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }
  
  .signUpButton {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .signInOption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: center;
  }
  
  .button {
    display: flex;
  }
  
  @media (min-width: 1440px) {
    padding: 4rem 4rem 4rem 4rem;
    width: 37.0625rem;

    .nameField {
      margin-top: 2rem;
    }
    
    .emailField {
      margin-top: 2rem;
    }
    
    .passwordField {
      margin-top: 2rem;
    }
    
    .confirmPasswordField {
      margin-top: 2rem;
    }
    
    .bottomContent {
      margin-top: 3rem;
    }
    
    .signInOption {
      margin-top: 2rem;
    }
    
  }
}
