.errorPage {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 3.5rem 0rem 0rem 0rem;
  background-color: rgb(241, 243, 244);
  height: 100%;

  .informationBlock {
    display: flex;
    width: 28.25rem;
  }
  
  @media (min-width: 1440px) {
    padding: 6rem 0rem 0rem 0rem;

    .informationBlock {
      width: 37.0625rem;
    }
    
  }
}
