.forgotPasswordBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 3rem 3rem 3rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  width: 28.25rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
  }
  
  .logoWithHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .emailField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .signUpButton {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }
  
  @media (min-width: 1440px) {
    padding: 1.5rem 4rem 4rem 4rem;
    width: 37.0625rem;

    .emailField {
      margin-top: 2rem;
    }
    
    .signUpButton {
      margin-top: 3rem;
    }
    
  }
}
