.teamCardAddNewButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .icon {
    display: block;
    width: 3rem;
    height: 3rem;
  }
  
  .button {
    display: flex;
    margin-top: 1rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(17, 83, 170);
  }

  &:hover {
    outline: 1px solid rgb(17, 83, 170);

    .button { // MANUAL OVERRIDE
      background-color: rgb(17, 83, 170);
      
      div p {
        color: #FFFFFF;
      }
    }
  }

  @media (min-width: 1440px) {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 13.75rem;

    .button {
      margin-top: 1.5rem;
    }
    
    &:focus-visible {
      outline: 1px dashed rgb(17, 83, 170);
    }

    &:hover {
      outline: 1px solid rgb(17, 83, 170);
    }
  }
}

.teamCardAddedButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-left: 2rem; // MANUAL OVERRIDE
  }
  
  .spacerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    flex: 1 1 auto;
  }
  
  .initials {
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    flex: 1 1 auto;
  }
  
  .iconDropDown {
    display: flex;
  }
  
  .titleWithDescription {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.25rem;
  }
  
  .role {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 13.75rem;

    .content {
      justify-content: center;
    }

    .header { // MANUAL OVERRIDE
      margin-left: 2.75rem;
    }
    
    .initials {
      width: 5rem;
      height: 5rem;
    }
    
    .titleWithDescription {
      margin-top: 0.5rem;
    }
    
    .role {
      margin-top: 1.5rem;
    }
  }
}

.teamCardAddedNoButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .initials {
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .titleWithDescription {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.25rem;
  }
  
  .role {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 13.75rem;

    .content {
      justify-content: center;
    }
    
    .initials {
      width: 5rem;
      height: 5rem;
    }
    
    .titleWithDescription {
      margin-top: 0.5rem;
    }
    
    .role {
      margin-top: 1.5rem;
    }
    
  }
}

.teamCardPendingButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .spacerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 4.625rem;
  }
  
  .icon {
    display: block;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 4.625rem;
  }
  
  .iconDropDown {
    display: flex;
  }
  
  .email {
    display: flex;
    width: 13rem;
    margin-top: 0.25rem;
  }
  
  .status {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  @media (min-width: 990px) {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 13.75rem;

    .header {
      justify-content: center;
    }
    
    .spacerContainer {
      width: 4rem;
    }
    
    .icon {
      width: 5rem;
      height: 5rem;
    }
    
    .buttonContainer {
      width: 4rem;
    }
    
    .email {
      margin-top: 0.5rem;
    }
    
  }
}

.teamCardPendingNoButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;
  width: 15rem;
  height: 10.625rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .email {
    display: flex;
    width: unset;
    margin-top: 0.25rem;
    align-self: center;
  }
  
  .status {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1440px) {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 13.75rem;

    .header {
      justify-content: center;
    }
    
    .icon {
      width: 5rem;
      height: 5rem;
    }
    
    .email {
      margin-top: 0.5rem;
    }
    
  }
}
