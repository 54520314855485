.mainLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .topNav {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: calc(100% - 4rem);
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .leftNav {
    display: flex;
    width: 13rem;
    height: unset;
    align-self: stretch;
  }
  
  .teamsPage {
    display: flex;
    width: unset;
    height: 100%;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1440px) {

    .leftNav {
      width: 17.625rem;
    }
    
  }
}

.notificationContainer {
  left: 50%;
  transform: translateX(calc(-50% + 6.5rem));

  @media (min-width: 1440px) {
    transform: translateX(calc(-50% + 8.5rem));
  }
}
