.logoWithHeaderTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 0rem 0.8125rem;
    width: unset;
    align-self: stretch;
  }
  
  .spindlLogo {
    display: block;
    width: 6.328125rem;
    height: 2.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: center;
    margin-top: 0.5rem;
  }
  
  @media (min-width: 583px) {
    justify-content: center;

    .spindlLogo {
      width: 7.59375rem;
      height: 3rem;
    }
    
    .text {
      margin-top: 1rem;
    }
    
  }
}

.logoWithHeaderTitleWithDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 0rem 0.8125rem;
    width: unset;
    align-self: stretch;
  }
  
  .spindlLogo {
    display: block;
    width: 6.328125rem;
    height: 2.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: center;
  }
  
  .lowerText {
    display: flex;
    width: unset;
    align-self: center;
  }
  
  @media (min-width: 583px) {
    justify-content: center;

    .spindlLogo {
      width: 7.59375rem;
      height: 3rem;
    }
    
    .bottomContent {
      margin-top: 1rem;
    }
    
  }
}
