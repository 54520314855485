.teamsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(241, 243, 244);
  height: 56.25rem;

  .teamCardList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    height: 64rem;
  }
}

.teamsBlockLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 243, 244);
  height: 100%;

  .spinner {
    width: 4rem;
    height: 4rem;
    margin: auto;

    .path {
      stroke: rgb(4, 95, 216);
    }
  }

  @media (min-width: 1440px) {
    height: 100%;
  }
}

